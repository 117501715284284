<template>
  <!-- IMAGE CONTAINER -->
  <div>
    <img
      :class="displayImage != null ? 'a-card-img' : 'a-default-card-img'"
      :src="
        displayImage != null
          ? displayImage
          : require('@/assets/images/public/image.svg')
      "
    />

    <FileUpload
      mode="basic"
      name="demo[]"
      accept="image/*"
      :maxFileSize="1000000"
      @select="onImageUpload"
      :chooseLabel="'Editar'"
    />
  </div>

  <div class="a-buttons">
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      @click="cancel"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="editBanner"
    >
      Aceptar
    </button>
  </div>

  <Toast />
</template>

<script>
import BannerAPI from "@/services/banner.js";
import UserAPI from "@/services/users.js";
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      localItem: {
        attached: null,
      },
      isLoading: false,
    };
  },
  emits: ["onSuccess", "onExit"],
  computed: {
    displayImage() {
      if (typeof this.localItem.attached == "string") {
        return this.localItem.attached;
      } else if (
        typeof this.localItem.attached == "object" &&
        this.localItem.attached != null
      ) {
        return URL.createObjectURL(this.localItem.attached);
      } else {
        return null;
      }
    },
    isValid() {
      return typeof this.localItem.attached == "string" ||
        this.localItem.attached == null
        ? false
        : true;
    },
  },
  methods: {
    cancel() {
      this.$emit("onExit");
    },
    onImageUpload(event) {
      //event.files == files to upload
      this.localItem.attached = event.files[0];
    },
    editBanner() {
      this.isLoading = true;
      var form_data = new FormData();
      for (var key in this.localItem) {
        form_data.append(key, this.localItem[key]);
      }

      BannerAPI.update(this.item.id, form_data)
        .then(() => {
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.editBanner();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
  mounted() {
    this.localItem = this.item;
  },
};
</script>

<style>
.p-dialog .p-dialog-content .p-inputtext {
  margin-bottom: 2px;
}

.p-fileupload-basic {
  position: relative;
  margin: -65px 0 20px 10px;
}

.p-fileupload .p-button,
.p-fileupload .p-button:hover {
  color: white;
  background: var(--text-color);
  min-width: 98px;
  width: 98px;
  max-height: 48px;
  min-height: 48px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-button .p-button-icon-left {
  display: none;
}
</style>
