<template>
  <Menubar />
  <div id="a-banners-container">
    <MainContainer>
      <template v-slot:header>
        <span id="a-banner-text"
          >Tamaño de banner recomendado 1920px X 300px</span
        >
      </template>
      <div id="a-banner-table">
        <DataTable :value="banners" :rows="5" ref="table">
          <Column field="title" />
          <!-- Img field -->
          <Column>
            <template #body="slotProps">
              <img
                v-if="slotProps.data.attached"
                :src="slotProps.data.attached"
                class="a-img-table"
                @click="detailBanner(slotProps.data)"
              />
            </template>
          </Column>
          <Column field="name" />

          <!-- State field -->
          <Column headerStyle="width: 9rem">
            <template #body="slotProps">
              <div id="a-table-switch">
                <InputSwitch
                  v-model="slotProps.data.active"
                  @change="onStateChange(slotProps.data)"
                />
              </div>
            </template>
          </Column>

          <!-- Action buttons -->
          <Column headerStyle="width: 9rem">
            <template #body="slotProps">
              <div class="a-container-tab-btns">
                <button
                  class="a-icon-tab-btn a-edit-btn"
                  @click="editBanner(slotProps.data)"
                >
                  <img :src="require('@/assets/icons/tool-white.svg')" />
                </button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </MainContainer>
  </div>

  <ConfirmDialog />

  <Dialog
    v-model:visible="settings.editModal"
    v-if="settings.editModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '80vw' }"
    :closable="false"
  >
    <template #header>
      <h3>
        Banner <span class="a-id-badge">{{ settings.banner.id }}</span>
      </h3>
    </template>
    <EditBanner
      :item="settings.banner"
      @onSuccess="bannerEdited"
      @onExit="onCancel()"
    />
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '640px': '90vw' }"
    :style="{ width: '80vw' }"
  >
    <template #header>
      <h3>
        Banner <span class="a-id-badge">{{ settings.banner.id }}</span>
      </h3>
    </template>
    <img
      class="a-img-modal"
      v-if="settings.banner.attached"
      :src="settings.banner.attached"
    />
  </Dialog>
  <Toast />
</template>

<script>
import Menubar from "@/components/navigation/Top.vue";
import MainContainer from "@/components/secure/MainContainer.vue";
import BannerAPI from "@/services/banner.js";
import UserAPI from "@/services/users.js";
import EditBanner from "@/components/banners/EditBanner.vue";

export default {
  name: "Banner",
  components: {
    Menubar,
    MainContainer,
    EditBanner,
  },
  data() {
    return {
      banners: [],
      settings: {
        detailModal: false,
        banner: null,
        editModal: false,
        key: 0,
      },
    };
  },
  methods: {
    getBanners() {
      BannerAPI.index()
        .then((response) => {
          this.banners = response.data;
          this.banners.forEach((element, index) => {
            var imgIndex = element.attached.lastIndexOf("/") + 1;
            var filename = element.attached.substr(imgIndex);
            this.banners[index].name = filename;
          });
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getBanners();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },

    onStateChange(banner) {
      BannerAPI.updateState(banner)
        .then(() => {
          this.$toast.add({
            severity: "success",
            detail: banner.active
              ? "El banner ha sido publicado"
              : "Se retiró la publicación del banner",
            life: 3000,
          });
          this.getBanners();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.onStateChange();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    detailBanner(item) {
      this.settings.banner = item;
      this.settings.detailModal = true;
    },
    onCancel() {
      this.getBanners();
      this.settings.banner = null;
      this.settings.editModal = false;
      this.settings.detailModal = false;
    },
    editBanner(item) {
      this.settings.banner = item;
      this.settings.editModal = true;
    },
    bannerEdited() {
      this.settings.banner = null;
      this.settings.editModal = false;
      this.getBanners();
      this.$toast.add({
        severity: "success",
        detail: "Se ha editado un banner",
        life: 3000,
      });
    },
  },
  mounted() {
    this.getBanners();
  },
};
</script>

<style scoped>
#a-banner-text {
  font-family: Quicksand;
  font-size: 19px;
  font-weight: 900;
  letter-spacing: -0.57px;
  text-align: left;
  color: var(--color-2);
  margin-bottom: 20px;
}
</style>

<style>
#a-banner-table .p-datatable .p-datatable-thead > tr > th {
  display: none !important;
}

#a-banner-table .p-datatable .p-datatable-tbody > tr > td {
  background: white !important;
}

#a-banners-container .a-main-content {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}
</style>

<style>
#a-table-switch .p-inputswitch {
  width: 60px;
}

#a-table-switch .p-inputswitch .p-inputswitch-slider {
  height: 26px;
}

#a-table-switch
  .p-inputswitch.p-inputswitch-checked
  .p-inputswitch-slider:before {
  transform: translateX(30px);
}
#a-table-switch .p-inputswitch .p-inputswitch-slider:before {
  width: 20px;
  height: 20px;
  margin-top: -0.625rem;
  left: 5px;
}
</style>
