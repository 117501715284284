import { render } from "./Banners.vue?vue&type=template&id=fbdbe80e&scoped=true"
import script from "./Banners.vue?vue&type=script&lang=js"
export * from "./Banners.vue?vue&type=script&lang=js"

import "./Banners.vue?vue&type=style&index=0&id=fbdbe80e&scoped=true&lang=css"
import "./Banners.vue?vue&type=style&index=1&id=fbdbe80e&lang=css"
import "./Banners.vue?vue&type=style&index=2&id=fbdbe80e&lang=css"
script.render = render
script.__scopeId = "data-v-fbdbe80e"

export default script